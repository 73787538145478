.slider-item {
  text-align:center;
  background-size: cover;

  position: relative;
  z-index: 1;
  &::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: .5;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .container {
    position: relative;
    display: table;
    max-width: 1170px;
    height: 100%;
  }
  .slide-inner {
    -webkit-transform: translate(0,-30%);
    transform: translate(0,-30%);
    top: 50%;
    left: 0;
    right:0;
    position: absolute;
  }
  h1 {
    color:$light;
    font-weight:bold;
    font-size:60px;
  }
  p {
    color:$light;
  }
  .btn-main {
    margin-top:25px;
  }
  &.white-bg {
    .slide-inner {
      h1 {
        color:$black;
      }
      p {
        color:$black;
      }
    }  
  }
}

.home-slider {
  &:hover {
    .owl-nav {
      opacity: 1;
    }
  }
  .owl-nav {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    right: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    .owl-next , .owl-prev {
      width: 60px;
      height: 60px;
      display:inline-block;
      background:$light;
      text-align:center;
    }
    .owl-next {
      right: 0px;
      position:absolute;
    }
    i {
      line-height:60px;
      font-size:20px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}


.hero-slider {
  .slider-item .container {
    height: 600px;
    .row {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .slider-item:focus {
    outline: 0;
  }
  .btn {
    font-size: 15px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-family: "Roboto Condensed";
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    outline: none;
    box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 12px 26px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
    border-radius: 0;
    transition: 0.3s;
    &:hover {
      color: black;
      background-color: #fff;
    }
  }

  h1 {
    white-space: normal;
    font-size: 49px;
    line-height: 49px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    letter-spacing: -2px;
    font-family: "Playfair Display";
    margin-bottom: 35px;
  }
}
.heroSliderArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #fff;
  font-size: 16px;
  transition: 0.3s;
  z-index: 999;
  &:focus,
  &:hover {
    background-color: #000;
    color: #fff;
    outline: 0;
  }
  &.prevArrow {
    left: 20px;
  }
  &.nextArrow {
    right: 20px;
  }
  @include tablet {
    display: none !important;
  }
}

