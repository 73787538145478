.sidebar {

}

.widget {
  margin-bottom:30px;
  padding-bottom:35px;
  .widget-title {
    margin:0;
    padding-bottom:15px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  // Subscription Widget
  &.widget-subscription {
    .form-group {
      margin-bottom: 8px;
      input {
        font-size:12px;
        font-weight:200;
        height:45px;
      }
    }
    .btn-main {
      width: 100%;
    }
  }
  // latest Posts
  &.widget-latest-post {
    .media {
      .media-object {
        width: 150px;
        height:auto;
      }
      .media-heading {
        a {
          color: $black;
          font-size: 14px;
          font-weight:300;
        }
      }
      p {
        font-size: 12px;
      }
    }
  } //end latest posts

  // Caterogry
  &.widget-category {
    ul {
      li {
        margin-bottom: 10px;
        a {
          color: #837f7e;
          font-weight:200;
          &:before {
            padding-right: 10px;
            content: "\f3d1";
            font-family: $icon-font;
          }
          &:hover {
            color:$primary-color;
            padding-left: 5px;
          }
        }
      }
    }
  } //end caterogry

  // Tag Cloud
  &.widget-tag {
    ul {
      li {
        margin-bottom: 10px;
        display: inline-block;
        margin-right:5px;
        a {
          color: #837f7e;
          display: inline-block;
          padding:8px 15px;
          border:1px solid #dedede;
          border-radius: 30px;
          font-size: 12px;
          &:hover {
            color:$light;
            background: $primary-color;
            border:1px solid $black;
          }
        }
      }
    }
  }

}

