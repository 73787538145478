

/*=================================================================
  Pricing section
==================================================================*/

.pricing-table {
  .pricing-item {
    padding: 40px 55px 65px;
    background: $light;
    margin-bottom: 20px;
    a.btn-main {
      text-transform: uppercase;
      margin-top: 20px;
    }
     li {
      font-weight: 400;
      padding: 10px 0;
      color:#666;
      i {
        margin-right: 6px;
      }
    }
  }
  .price-title {
    padding: 30px 0 20px;
     > h3 {
      font-weight: 700;
      margin: 0 0 5px;
      font-size: 15px;
      text-transform: uppercase;
    }
    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 5px;
    }
    .value {
      color: $primary-color;
      font-size: 50px;
      padding: 10px 0;
    }
  }
 
}


