
// Variables
$light: #fff;
$primary-color: #000;
$secondary-color: #1bbb1b;
$success: #1bbb1b;
$black: #000;
$alert:#c7254e;
$border-color:#dedede;
$primary-font:'Poppins', sans-serif;
$icon-font: 'themefisher-font';